import React, { useEffect, useState } from "react"
import { Alert, Col, Container, Row } from "react-bootstrap"
import './style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import * as emailjs from "emailjs-com";
import { useLocation } from "react-router-dom";
import ScheduleGoogleMeet from "../../Components/schedule-google-meet";
import ScheduleMeetingForm from "../../Components/create-meet";



export default function Contact() {
  const contactConfig = {
    YOUR_EMAIL: "samilalgul@softcaveware.com",
    YOUR_FONE: "+90(531)567-9097",
    description: 'Hizmetler hakkında bilgi almak için mesajınızı buradan gönderebilir veya randevu oluşturabilirsiniz.',
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_qu4fxhj",
    YOUR_TEMPLATE_ID: "template_5iefdyk",
    YOUR_USER_ID: "WmTXtM2b63EGCCDCt",
  };
  const [formData, setFormdata] = useState({
    email: "",
    name: "",
    message: "",
    loading: false,
    show: false,
    alertmessage: "",
    variant: "",
  });

  const location = useLocation();

  useEffect(()=>{
    if(location.state?.service) {setFormdata({message:`Merhaba, \n${location.state?.service} hizmeti hakkında ayrıntılı bilgi almak istiyorum.`})}
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormdata({ loading: true });

    const templateParams = {
      from_name: formData.email,  
      user_name: formData.name,
      to_name: contactConfig.YOUR_EMAIL,
      message: formData.message,
    };

      emailjs
        .send(
          contactConfig.YOUR_SERVICE_ID,
          contactConfig.YOUR_TEMPLATE_ID,
          templateParams,
          contactConfig.YOUR_USER_ID
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormdata({
              loading: false,
              alertmessage: "Başarılı! ,Mesajınız iletildi.",
              variant: "success",
              show: true,
            });
          },
          (error) => {
            console.log(error.text);
            setFormdata({
              alertmessage: `${"Gönderilirken hata oluştu: "},${error.text}`,
              variant: "danger",
              show: true,
            });
            document.getElementsByClassName("co_alert")[0].scrollIntoView();
          }
        );
  };

  const handleChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
      <Container style={{backgroundColor:'transparent',marginTop:150}}>
      <Row className="mb-5 mt-3 pt-md-3">
        <Col lg="8">
          <h1 className="display-4 mb-4">{"Bilgi alın!"}</h1>
          <hr className="t_border my-4 ml-0 text-left" />
        </Col>
      </Row>
      <Row className="sec_sp">
        <Col lg="12">
          <Alert
            //show={formData.show}
            variant={formData.variant}
            className={`rounded-0 co_alert ${formData.show ? "d-block" : "d-none"
              }`}
            onClose={() => setFormdata({ show: false })}
            dismissible
          >
            <p className="my-0">{formData.alertmessage}</p>
          </Alert>
        </Col>
        <Col lg="5" className="mb-5">
          <h3 className="color_sec py-4">{"İletişim Bilgileri"}</h3>
          <address>
            <strong>Email:</strong>{" "}
            <a href={`mailto:samilalgul@softcaveware.com`}>
            samilalgul@softcaveware.com
            </a>
            <br />
          </address>
          <p>{contactConfig.description}</p>
        </Col>
        <Col lg="7" className="d-flex align-items-center">
          <form onSubmit={handleSubmit} className="contact__form w-100">
            <Row>
              <Col lg="6" className="form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="İsminiz"
                  value={formData.name || ""}
                  type="text"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col lg="6" className="form-group">
                <input
                  className="form-control rounded-0"
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={formData.email || ""}
                  required
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <textarea
              className="form-control rounded-0"
              id="message"
              name="message"
              placeholder={"Mesajınız"}
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <br />
            <Row>
              <Col lg="12" className="form-group">
                <button className="btn ac_btn" type="submit">
                  {formData.loading ? "Gönderiliyor..." : "Gönder"}
                </button>
              </Col>
            </Row>
            <ScheduleMeetingForm/>

          </form>
        </Col>
      </Row>
      </Container>
)
}